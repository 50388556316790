<template>
  <mobile-screen
    :header="true"
    screen-class="resources-filter-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("search", "filters", "search-filters") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section v-if="sections && sections.length">
      <ul
        v-for="section in sections"
        :key="section"
        class="clebex-item-section overflow-hidden"
      >
        <li
          class="clebex-item-section-item"
          :class="collapse[section] ? 'collapsed' : 'expanded'"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label larger-label">
                {{ section }}
              </label>
            </div>
            <span
              class="follow-up-icons cursor-pointer"
              @click="
                collapse[section]
                  ? (collapse[section] = false)
                  : (collapse[section] = true)
              "
            >
              <span class="follow-up-icon-item collapsable"
                ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
              /></span>
            </span>
          </div>
        </li>
        <!-- Applications section -->
        <section
          v-if="
            section === 'Applications' && applications && applications.length
          "
          :class="collapse[section] ? 'collapsed' : 'expanded'"
        >
          <li
            v-for="option in applications"
            :key="option.id"
            class="clebex-item-section-item no-bottom-border light"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilter(section, option.id)"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">{{
                  option.name
                }}</label>
              </div>
              <span
                v-if="selectedApplications.indexOf(option.id) !== -1"
                class="follow-up-icons"
              >
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
      </ul>
    </section>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              @click="$store.commit(`${searchFiltersStore}/clearAllFilters`)"
            >
              {{ displayLabelName("search.filters.clear-all") }}
            </button>
          </li>
          <li class="action">
            <button
              class="action-btn"
              @click="
                $store.commit(`${searchFiltersStore}/activateSearch`, true, {
                  root: true
                })
              "
            >
              {{ displayLabelName("search.filters.apply") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "SearchFilterCompanyDocuments",
  data() {
    return {
      collapse: {}
    };
  },
  computed: {
    ...mapState("applications", ["applications"]),
    sections() {
      return this.$store.state[this.searchFiltersStore].sections;
    },
    selectedSection() {
      return this.$store.state[this.searchFiltersStore].selectedSection;
    },
    selectedApplications() {
      return this.$store.state[this.searchFiltersStore].selectedApplications;
    }
  },
  created() {
    if (this.sections.indexOf("Applications") !== -1 && !this.applications) {
      this.getApplications();
    }
  },
  methods: {
    ...mapActions("applications", ["getApplications"]),
    setSelectedSection(section) {
      this.$store.dispatch(
        `${this.searchFiltersStore}/setSelectedSection`,
        section,
        { root: true }
      );
    },
    setSelectedApplications(id) {
      this.$store.dispatch(
        `${this.searchFiltersStore}/setSelectedApplications`,
        id,
        { root: true }
      );
    },
    selectSection(section) {
      if (section !== this.selectedSection) {
        this.setSelectedSection(section);

        if (section === "Applications" && !this.applications) {
          this.getApplications();
        }
      }
    },
    selectFilter(type, id) {
      if (type === "Applications") {
        this.setSelectedApplications(id);
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: false
    },
    searchFiltersStore: {
      type: String,
      required: true
    },
    disableModel: {
      type: Boolean,
      required: false
    }
  }
};
</script>
